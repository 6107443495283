import React from "react"
import { graphql } from "gatsby"
import Gallery from "../components/gallery"

const GalleryPage = ({ data, pageContext }) => {
  const { name, options } = pageContext
  const photos = data.allS3ImageAsset.edges.map((edge) => ({
    alt: edge.node.EXIF.UserComment || "",
    url: {
      childImageSharp: edge.node.childImageSharp,
    },
  }))
  return <Gallery name={name} options={options} photos={photos} />
}

export default GalleryPage

export const query = graphql`
  query PhotographyPostsQuery($name: String) {
    allS3ImageAsset(
      sort: { order: DESC, fields: EXIF___DateTime }
      filter: {
        Key: { regex: "/^(?!homepage/).*/" }
        EXIF: { DateCreatedISO: { eq: $name } }
      }
    ) {
      edges {
        node {
          id
          EXIF {
            DateTime
            DateCreated
            DateCreatedISO
            DateTimeOriginal
            ShutterSpeedValue
            Model
            LensModel
            ISO
            FocalLength
            FNumber
            ExposureTime
            UserComment
          }
          childImageSharp {
            gatsbyImageData(
              quality: 80
              placeholder: BLURRED
              breakpoints: [540, 1080, 1920]
            )
          }
        }
      }
    }
  }
`
